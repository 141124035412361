import { Base64 } from 'js-base64';
import Vue from 'vue'

var testAuth = null
export var openstageApi2Url = process.env.VUE_APP_API_URL;

export const setTestAuth = (auth) => {
  testAuth = auth
}

const getAuthUser = () => {
  if (testAuth != null) {
    return {auth: testAuth}
  }
  const authFromUrl = window.location.search?.split('&auth=')[1]
  if (authFromUrl) {
    Vue.$cookies?.set("uauth2", authFromUrl, '14d', null, getDomain(window.location.hostname));
    return JSON.parse(Base64.decode(authFromUrl))
  }
  const userAuthEncoded = Vue.$cookies?.get('uauth2')
  if (userAuthEncoded) {
    return JSON.parse(Base64.decode(userAuthEncoded))
  }
}   

const getArtistId = () => {
  const artistFromUrl = window.location.search.split('artistId=')[1]?.split('&')[0]
  if (artistFromUrl) {
    Vue.$cookies?.set("artistId", artistFromUrl, '14d', null, getDomain(window.location.hostname));
    return artistFromUrl
  }
  return Vue.$cookies?.get('artistId')
}   

const getDomain = (domain) => {
    const s = domain.split('.');
    if (s.length < 3) {
        return domain;
    }
    return s.slice(1).join('.');
}

const setAuthUser  = (userAuth) => {
    const userAuthEncoded = Base64.encode(JSON.stringify(userAuth));
    window.$cookies.set("uauth2", userAuthEncoded, '14d', null, getDomain(window.location.hostname));
}

const resetAuthUser = () => {
    window.$cookies.remove('uauth2', null, getDomain(window.location.hostname));
}

const authHeader = () => {
    // return authorization header with jwt token

    const userAuth = getAuthUser();

    if (userAuth) {
        return { 'Authorization': 'Bearer ' + userAuth.auth };
    } else {
        return {};
    }
}

export { authHeader, getAuthUser, setAuthUser, resetAuthUser, getArtistId };
